
import { getCurrentInstance, inject, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { addConfig, deleteConfig, deliveryServiceConfig, getHistory, getServiceConfig } from '@/api/settings/config';
import dateFormat from '@/utils/date-format';
import { getShowBool } from '@/utils/permission-show-module';
import { CONFIG_LEVEL, isRefrence } from '../utils/permisson';
import { ElMessageBox } from 'element-plus';
import { alloverEdit } from '@/layout/messageCenter/routerRef';
import ServiceConfigEditDialog from './ServiceConfigEditDialog.vue';
import {
  ServiceConfigSensitives as serviceConfigSensitives,
  ServiceConfigTypes as serviceConfigTypes,
} from './ServiceConfig';

export default {
  name: 'ServerConfigInfo',
  props: {
    id: {
      type: Number,
      default: 0,
    },
    status: {
      type: Number,
      default: 0,
    },
    configType: {
      type: String,
      default: 'service',
    },
    minHeight: {
      type: Number,
      default: 100,
    },
    isGW: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ServiceConfigEditDialog,
  },
  inheritAttrs: false,
  emits: ['back'],
  setup(props: { id: number; status: number }, { emit }: { emit: Function }) {
    const { proxy } = getCurrentInstance() as any;
    const serviceId = inject('serviceId') as number;
    const serviceStatus = ref(props.status);
    const serviceConfigEditDialogRef = ref();

    const state = reactive({
      tableData: [] as any[],
      detailInfo: {} as any,
      historyInfo: {} as any,
      isOpenHistoryDialog: false,
    });

    const historyTableColumns = [
      { label: '键', prop: 'name' },
      { label: '值', prop: 'value' },
      { label: '默认值', prop: 'defaultValue' },
    ];

    // 获取服务配置数据
    const getTableData = async () => {
      const { data } = await getServiceConfig(String(props.id));
      state.tableData = data.map((item: any) => {
        let key = item.name;
        let serviceName = '';
        if (item.name.indexOf('mergedConf') > -1) {
          [serviceName, ...key] = item.name.replace('mergedConf.', '').split('.');
          key = key.join('.');
        }

        return {
          ...item,
          name: key,
          serviceName,
          isEdit: false,
        };
      });
      // state.tableData.push({ name: '', value: '', defaultValue: '', type: '', version: '', isEdit: true });
    };

    onMounted(() => {
      getTableData();
    });

    watch(
      () => props.id,
      () => {
        getTableData();
      },
    );

    // 打开变更历史弹窗
    const openHistoryDialog = async (rowData: any) => {
      const searchProps = {
        page: 1,
        pageSize: 20,
      };
      const { data } = await getHistory(rowData.id, searchProps);
      state.historyInfo = data.rows;
      state.isOpenHistoryDialog = true;
    };

    // 添加配置项
    const addItem = (index: number) => {
      state.tableData.splice(index + 1, 0, {
        name: '',
        value: '',
        defaultValue: '',
        type: '',
        version: '',
        scope: 0,
        isEdit: true,
        sensitive: 0,
      });
      alloverEdit.value = true;
    };

    // 保存新增配置项修改
    const saveModify = async (rowData: any) => {
      const configData = {
        ...rowData,
        serviceId,
        scope: CONFIG_LEVEL.SERVICE,
      };
      // 数据校验
      const RegName = /^[a-zA-Z][A-Za-z0-9-._]+$/;
      if (!RegName.test(configData.name)) {
        proxy.$message({
          type: 'warning',
          message: '键只能以英文字母开头，英文字母、数字、点、下划线结尾!',
        });
        return;
      }
      if (configData.type === '') {
        proxy.$message({
          type: 'warning',
          message: '请选择类型！',
        });
        return;
      }

      const { code } = await addConfig(configData);
      if (code === 0) {
        proxy.$message({
          type: 'success',
          message: '保存成功',
        });
        getTableData();
      }

      alloverEdit.value = false;
    };

    // 取消新增配置项修改
    const cancelModify = (index: number) => {
      state.tableData.splice(index, 1);
      alloverEdit.value = false;
    };

    // 删除配置项
    const deleteItem = (rowData: any) => {
      ElMessageBox.confirm('确认是否删除,删除后不可恢复', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const { code } = await deleteConfig(rowData.id);
        if (code === 0) {
          proxy.$message({
            type: 'success',
            message: '删除成功',
          });
          getTableData();
        }
      });
    };

    const deliveryList = async () => {
      const { code } = await deliveryServiceConfig(String(props.id));
      if (code === 0) {
        proxy.$message({
          type: 'success',
          message: '下发成功',
        });
      }
    };
    const isRefrenceService = inject(isRefrence);

    const resetTableList = () => {
      state.tableData = state.tableData.filter((item) => !item.isEdit);
    };

    const handleClose = () => {
      const hasEdit = state.tableData.find((item) => item.isEdit);

      if (hasEdit) {
        ElMessageBox.confirm(`编辑中的数据尚未保存，是否退出?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          resetTableList();
          emit('back');
        });
      } else {
        resetTableList();
        emit('back');
      }
    };

    const handleOpenEditDialog = (type: string, row: any) => {
      serviceConfigEditDialogRef.value.handleOpenDialog({
        type,
        payload: row,
      });
    };

    return {
      ...toRefs(state),
      dateFormat,
      historyTableColumns,
      openHistoryDialog,
      addItem,
      saveModify,
      cancelModify,
      deleteItem,
      deliveryList,
      getShowBool,
      serviceStatus,
      isRefrenceService,
      handleClose,
      serviceConfigEditDialogRef,
      handleOpenEditDialog,
      getTableData,
      serviceConfigTypes,
      serviceConfigSensitives,
    };
  },
};
