<template>
  <el-dialog title="配置详情" :width="640" v-model="visible" @close="handleCloseDialog" destroy-on-close>
    <el-form ref="formRef" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="键" prop="name" required>
        {{ form.name }}
      </el-form-item>
      <el-form-item label="值" prop="value">
        <el-input v-model.trim="form.value" placeholder="请输入值" v-if="isEdit" />
        <template v-else>{{ form.value }}</template>
      </el-form-item>
      <el-form-item label="默认值" prop="defaultValue">
        <el-input v-model.trim="form.defaultValue" placeholder="请输入默认值" v-if="canEdit" />
        <template v-else>{{ form.defaultValue }}</template>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择类型" v-if="canEdit" style="width: 100%">
          <el-option :label="item" :value="index" v-for="(item, index) in serviceConfigTypes" :key="item" />
        </el-select>
        <template v-else>{{ serviceConfigTypes[form.type] }}</template>
      </el-form-item>
      <el-form-item label="敏感类型" prop="sensitive">
        <el-select v-model="form.sensitive" placeholder="请选择敏感类型" v-if="canEdit" style="width: 100%">
          <el-option :label="item" :value="index" v-for="(item, index) in serviceConfigSensitives" :key="item" />
        </el-select>
        <template v-else>{{ serviceConfigSensitives[form.sensitive] }}</template>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <template v-if="isEdit">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="handleCloseDialog">取消</el-button>
      </template>
      <template v-else>
        <el-button @click="handleCloseDialog">关闭</el-button>
      </template>
    </div>
  </el-dialog>
</template>
<script>
import { updateConfig } from '@/api/settings/config';
import { ElMessage } from 'element-plus';
import { computed, defineComponent, reactive, ref } from 'vue';
import {
  ServiceConfigTypes as serviceConfigTypes,
  ServiceConfigSensitives as serviceConfigSensitives,
} from './ServiceConfig';
export default defineComponent({
  name: 'ServiceConfigEditDialog',
  props: {
    isRefrenceService: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const isEdit = ref(false);
    const committing = ref(false);
    const baseData = {
      id: null,
      serviceId: null,
      name: '',
      value: '',
      defaultValue: '',
      sensitive: null,
      type: null,
    };
    const form = reactive({ ...baseData });
    const formRef = ref();
    const rules = {
      type: [
        {
          required: true,
          message: '请选择类型',
          trigger: 'blur',
        },
      ],
      sensitive: [
        {
          required: true,
          message: '请选择敏感类型',
          trigger: 'blur',
        },
      ],
    };
    const handleCloseDialog = () => {
      visible.value = false;
      committing.value = false;
      Object.keys(baseData).forEach((k) => (form[k] = baseData[k]));
      formRef.value.resetFields();
    };

    const handleOpenDialog = ({ type, payload }) => {
      Object.keys(payload).forEach((k) => (form[k] = payload[k]));
      isEdit.value = type === 'edit';
      visible.value = true;
    };

    const handleSubmit = async () => {
      const valid = await formRef.value.validate();
      if (!valid) {
        return;
      }
      committing.value = true;
      const saveData = {
        value: form.value,
        defaultValue: form.defaultValue,
        sensitive: form.sensitive,
        type: form.type,
        serviceId: form.serviceId,
      };
      try {
        const { code } = await updateConfig(form.id, saveData);
        if (code === 0) {
          emit('change');
          ElMessage.success('编辑成功');
        }
      } catch (e) {
        console.log(e);
      } finally {
        committing.value = false;
      }
      handleCloseDialog();
    };

    const canEdit = computed(() => isEdit.value && !props.isRefrenceService);

    return {
      visible,
      form,
      formRef,
      rules,
      handleCloseDialog,
      handleOpenDialog,
      serviceConfigTypes,
      serviceConfigSensitives,
      isEdit,
      handleSubmit,
      canEdit,
    };
  },
});
</script>
