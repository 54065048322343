import { buildService, getChangesApply, startCheck, stopService } from '@/api/servers';
import { reactive, ref } from 'vue';
import { release } from '@/views/service-management/business-service/utils/service-release-data-utils';
import Message from 'element-plus/es/el-message';
import { ElMessage, ElLoading } from 'element-plus';
export const thenRefresh = ref(false);

export const serverInfo = ref({} as any);
export const relationIdArray = ref([] as any);
export const serverList = reactive([] as any[]);

export const externalModels = ref([] as any);

export const currentServiceIdForData = ref('' as any);
export const buildServiceData = (branch = 'develop', userId = '123456') =>
  buildService({ serviceId: currentServiceIdForData.value, branch, userId });

/**
 * 初始化/应用变更
 * @returns
 */
export const handleChangeApply = async (ids?: any) => {
  const id = ids || currentServiceIdForData.value;
  const loadingInstance = ElLoading.service({
    text: '服务变更中，请稍后...',
    background: 'rgba(0,0,0,0.2)',
  });
  try {
    const { code, data, message } = await getChangesApply(id);
    loadingInstance.close();
    if (code === 0) {
      if (data.modelCheckMsg) {
        if (data.modelName && data.fieldName) {
          ElMessage({
            type: 'error',
            duration: 10000,
            message: `数据对象"${data.modelName}"属性"${data.fieldName}"${data.modelCheckMsg}`,
          });
        } else {
          ElMessage({
            type: 'error',
            duration: 10000,
            message: data.modelCheckMsg,
          });
        }
        return Promise.reject(new Error(data.modelCheckMsg));
      }
      thenRefresh.value = !thenRefresh.value;
      return Promise.resolve(true);
    }
    throw message;
  } catch (error) {
    loadingInstance.close();
    return Promise.reject(new Error('初始化失败'));
  }
};

/**
 * 停止服务
 */
export const stopServiceData = (id?: any) => {
  stopService({ serviceId: id || currentServiceIdForData.value })
    .then((res) => {
      if (res.code === 200) {
        Message.success('停止成功');
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const releaseServiceData = () => {
  release();
};

export const checkBeforeStart = async (id?: any) => {
  try {
    const { code, data } = await startCheck(id || currentServiceIdForData.value);
    return { code, data };
  } catch (error) {
    console.log(error);
  }
};
