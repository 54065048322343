export default {
  GET_AVAILABLE_DEPENDENCIES: ['/project/mergedService/availableDependency/list'],
  ADD_MERGE_SERVICE: ['/project/mergedService/add'],
  GET_DEPENDENCY_DETAIL: ['/project/mergedService/query'],
  UPDATE_MERGE_SERVICE: ['/project/mergedService/update'],
  SEARCH_MERGE_STATUS: ['/project/mergeStatus/query'],
  REBUILD_GATEWAY: ['/tool/rebuildGateway'],
  LIST_GATEWAY: ['/gateway/whiteurl/list'],
  EXPORT_URL_GATEWAY: ['/gateway/whiteurl/import'],
  UPDATE_AUTH_GATEWAY: ['/gateway/whiteurl/update/auth'],
  DELETE_URL_GATEWAY: ['/gateway/whiteurl/delete'],
  UPDATE_STATUS_GATEWAY: ['/gateway/whiteurl/update/status'],
  MODEL_API_LIST: ['/api/modelApiList'],
};
