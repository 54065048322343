import { apiProxy } from '@/api/proxy/proxy';
import SERVER_TYPE from '@/shared/servertype';
import mergeDeploy from '@/shared/constant/url/mergeDeploy';
import { SuccessResponse } from '@/types/response';

export const getAvailableDependencies = (payload?: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.GET_AVAILABLE_DEPENDENCIES, {
    method: 'get',
    params: payload,
  });

export const addMergeService: (payload: any) => Promise<SuccessResponse<null>> = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.ADD_MERGE_SERVICE, {
    method: 'POST',
    data: payload,
  });

export const queryDetail = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.GET_DEPENDENCY_DETAIL, {
    method: 'get',
    params: payload,
  });

export const updateService: (payload: any) => Promise<SuccessResponse<null>> = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.UPDATE_MERGE_SERVICE, {
    method: 'POST',
    data: payload,
  });

export const queryMergeStatus = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.SEARCH_MERGE_STATUS, {
    method: 'get',
    params: payload,
  });

export const rebuildGateway = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.REBUILD_GATEWAY, {
    method: 'get',
    params: payload,
  });

export const getGatewayList = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.LIST_GATEWAY, {
    method: 'get',
    params: payload,
  });
export const exportUrlGatewayList = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.EXPORT_URL_GATEWAY, {
    method: 'post',
    data: payload,
  });
export const updateAuthGateway = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.UPDATE_AUTH_GATEWAY, {
    method: 'post',
    data: payload,
  });
export const deletteUrlGateway = (id: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, `${mergeDeploy.DELETE_URL_GATEWAY}/${id}`, {
    method: 'post',
  });
export const updateStatusGateway = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.UPDATE_STATUS_GATEWAY, {
    method: 'post',
    data: payload,
  });
export const getModelApiList = (payload: any) =>
  apiProxy(SERVER_TYPE.SERVICE_GENERATOR, mergeDeploy.MODEL_API_LIST, {
    method: 'get',
    params: payload,
  });
